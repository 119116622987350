import { api } from "./Apis";


const loginUser=async(setUserDetails, data)=>{
    setUserDetails(data['name'],data['email'])
    
}
const signUp=()=>{

}

const logOut=(setUserDetails,setUserCart, toast)=>{
    setUserDetails(undefined,undefined,undefined)
    api('POST','logout',{}).catch(err=>undefined)
    setUserCart([])
    toast("Logout successful.","SUCCESS",2000)
}

const showLogin=(loginContext)=>{
    loginContext.setLoginVisibility(true)
}

const hideLogin=(loginContext)=>{
    loginContext.setLoginVisibility(false)
}
export {logOut , loginUser , signUp , showLogin ,hideLogin}