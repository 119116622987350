import '../../Styles/HomePage/Contact.css'
import React, { useContext, useState } from 'react'
import Carousel from '../../Components/Carousel/Carousel'
import { sliderData } from "../../DataLayer/slider-data";
import '../../Styles/HomePage/About.css'
import { LiaSteam } from "react-icons/lia";
import { SiEpicgames } from "react-icons/si";
import { BsInstagram } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";

import valorant_image from '../../assets/carousel/carousel_valorant.jpg'
import cs2_image from '../../assets/carousel/carousel_cs2_2.jpg'
import gtav_image from '../../assets/carousel/carousel_gtav_2.jpg'


import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Services from '../../Components/Service/Service';
import { api } from '../../util/Apis';
import Toast from '../../DataLayer/Toast';

sliderData[0].image=valorant_image;
sliderData[1].image=cs2_image;
sliderData[2].image=gtav_image;

const Homepage = () => {
  return (
    <>
      <Carousel sliderData={sliderData}/>
      <About/>
      <Services/>
      <Contact/>
    </>
  )
}

export default Homepage

const About = () => {
  return (
    <div className='about_container row' id='about'>
        <div className="about_wrapper">
          <section className=" about_left  col-md-6 col-12">
              <section style={{color:"black"}} className="about_left_left col-md-5 col-12 d-none d-md-flex">
                <section className='about_left_left_upper col-12 shiny_glass_effect'></section>
                <section className='about_left_left_lower col-12 shiny_glass_effect'>
                    {/* <div className="about_experience_years row">
                       <section className='logo col-4'><GiStarMedal/></section>
                       <section className="col-5">10 Years</section>
                    </div>
                    <div className="about_experience_text row">
                        <div className="row">Working</div>
                        <div className="row">Experience</div>
                    </div> */}
                </section>
              </section>
              <section className="about_left_right col-md-6 col-12">
                <section className='img shiny_glass_effect'>

                </section>
              </section>
          </section>
          <section className=' about_right col-md-6 col-11'>
            <div className="row">
              <span className='welcome_text'>Welcome to SmurfElite</span>
            </div>
            <div className="row">
              <span className="about_right_heading">
                  We Made Digital 
              </span>
              <span className="about_right_heading">
                   Shopping Very Simple
              </span>
            </div>
            <div className="row">
              <p className='about_right_para1'>Effortless online shopping provides a user-friendly interface, quick navigation, and reliable transactions, ensuring a hassle-free and enjoyable purchasing process.</p>
            </div>
            <div className="row">
               <section className="about_right_icon_text">
                  <section className='icon_container'>
                      <LiaSteam />
                  </section>
                  <section >
                    <span >
                      Quality Game Accounts
                    </span>
                    <p>Our game accounts guarantee trustworthiness, hassle-free transactions, and a zero-ban assurance, ensuring a secure and enjoyable gaming experience for all players.</p>
                  </section>
               </section>
            </div>
            <div className="row">
               <section className="about_right_icon_text">
                  <section className='icon_container'>
                  <SiEpicgames />
                  </section>
                  <section >
                    <span >
                    Best Shopping Assistance
                    </span>
                    <p>Need help choosing or buying accounts? Contact us for expert assistance and personalized guidance throughout the process. We're here for you!</p>
                  </section>
               </section>
            </div>
            <div className='about_right_button'>
              <ButtonPrimary title={'Contact Us'} href={'#contact_us'}/>
            </div>
          </section>
        </div>
    </div>
  )
}


const Contact = () => {
  const { toast } = useContext(Toast)
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSendingEmail(true);
    let res = await api("POST", "contactusemail", { ...contactForm });
    if (res === undefined) {
      toast("Message not sent.", "DANGER", 1500);
    } else {
      toast("Message sent successfully.", "SUCCESS", 1500);
    }
    setContactForm({
      name: "",
      email: "",
      phone: "",
      message: ""
    });
    setIsSendingEmail(false);
  };

  const onChangeHandler = (name, value) => {
    setContactForm((val) => ({ ...val, [name]: value }));
  };

  return (
    <div className='contact_wrapper row' id='contact_us'>
      <div className="contact">
        <div className="contact_left">
          <span className='title_arrow'>Contact Us</span>
          <h2>Connect with us for personalized support and exceptional service today!</h2>
          <div>
            <section className="icon" onClick={e=>window.open('https://www.instagram.com/smurf.elite/', '_blank')} ><BsInstagram /></section>
            <section>
              <a href='https://www.instagram.com/smurf.elite/' target='_blank' rel='noreferrer' >
                <h3>Smurf Elite Instagram</h3>
              </a>
            </section>
          </div>
          <div>
            <section className="icon" onClick={e=>window.open('mailto:help@smurfelite.com')} ><HiOutlineMail /></section>
            <section>
              <a href="mailto:help@smurfelite.com"><h3>Feel Free to Mail Us</h3></a>
            </section>
          </div>
        </div>
        <div className="contact_right">
          <form onSubmit={(e) => onSubmitHandler(e)}>
            <input
              type="text"
              name="name"
              value={contactForm.name}
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              placeholder='Your Name'
              required
            />
            <input
              type="email"
              name="email"
              value={contactForm.email}
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              placeholder='Email Address'
              required
            />
            <input
              type="number"
              name="phone"
              value={contactForm.phone}
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              placeholder='Phone Number'
            />
            <textarea
              cols="36"
              name="message"
              value={contactForm.message}
              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
              placeholder='Write a Message'
              required
              rows="7"
            ></textarea>
            <button type="submit" disabled={isSendingEmail}>
              {isSendingEmail ? <section className='loading'></section> : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
