import React, { useContext, useEffect, useLayoutEffect} from 'react';
import { api } from '../../../util/Apis';
import { userContext } from '../../../DataLayer/UserContext';
import Toast from '../../../DataLayer/Toast';
import { useNavigate } from 'react-router-dom';
import constants from '../../../util/Constants';
import { PaymentContext } from '../../Provider/Provider';

// 4399 9200 0664 7853
// 05/27
// 842
// K1A 0B1
// 416-555-1234
// subhamdas2105@gmail.com

const PayPalButton = ({ isReadyToCheckout, cart }) => {
  const user = useContext(userContext).userState;
  return (
    <>
      <PaypalScript />
      {isReadyToCheckout ? <PayPalButtons productIds={cart} user={user} /> : <></>}
    </>
  );
};

export default PayPalButton;

const createItemsObject = async (itemsArray, email, toast) => {
  try {
    const res = await api('POST', 'createpaypalorder', {
      email,
      items: itemsArray
    });
    return res.data;
  } catch (error) {
    toast("Failed to create order", "DANGER", 1500)
    return null;
  }
}

const PaypalScript = () => {

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`; // Replace with actual PayPal Client ID
    script.async = true;

    
    document.head.appendChild(script);
    
    return () => {
      const existingScript = document.querySelector(`script[src="https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}"]`);
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);

  return <></>;
}

const PayPalButtons = ({productIds, user}) => {
  const {toast} = useContext(Toast);
  const navigate = useNavigate();
  const {setPaymentStatusObject}=useContext(PaymentContext)
  useEffect(() => {
    return () => {
      setPaymentStatusObject(val=>({...val,visibility: false}))
    }
  })

  const createOrder = async (data, actions) => {
    const itemsObject = await createItemsObject(productIds, user.email,toast);
    sessionStorage.setItem('orderId', itemsObject?.order_id);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: String(itemsObject.value),
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: String(itemsObject.value)
              }
            }
          },
          items: itemsObject.items,
          description: 'Order description',
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    const details = await actions.order.capture();
    const paymentId = details.id;
    const items = details.purchase_units[0].items;
    const value = details.purchase_units[0].amount.value;

    
    try {
      const res = await api('POST', 'verifypaypalpayment', {
        email: user.email,
        paymentId,
        items,
        value,
        orderId: sessionStorage.getItem('orderId')
      });
      toast("Payment Recieved.","SUCCESS",1000);
      setTimeout(() => {
        toast("Processing Accounts to send..","CUSTOM",2000);
      }, 1000);
      // toast("Game credentials has been sent to your email.","SUCCESS",1500);
      navigate('/orders', {state : {orderId : sessionStorage.getItem('orderId')}});
      sessionStorage.setItem(constants.orders.session.ORDER_ID_KEY, sessionStorage.getItem('orderId'));
      sessionStorage.removeItem('orderId');
      setPaymentStatusObject(val=>({...val,visibility: false})) 
    } catch (err) {
      toast("Payment Failed. Payment not received.",constants.toast.STATUS_TEXT.DANGER,1500);
      sessionStorage.removeItem('orderId');
      setPaymentStatusObject(val=>({...val,visibility: false}))
    }
  };

  const onError = (err) => {
    toast("Payment Failed. Payment not received.",constants.toast.STATUS_TEXT.DANGER,1500);
    setPaymentStatusObject(val=>({...val,visibility: false}))
  }

  useEffect(() => {
    const paypalButtonContainer = document.getElementById('paypal-button-container');

    if (paypalButtonContainer && paypalButtonContainer.hasChildNodes()) return;
    window.paypal.Buttons({
      createOrder,
      onApprove,
      onError
      
    }).render('#paypal-button-container');
  }, []);

  return (
    <div>
      <div id="paypal-button-container"></div>
    </div>
  );
};

// {
//     "id": "70S48848NW191334T",
//     "intent": "CAPTURE",
//     "status": "COMPLETED",
//     "purchase_units": [
//         {
//             "reference_id": "default",
//             "amount": {
//                 "currency_code": "USD",
//                 "value": "8634.00",
//                 "breakdown": {
//                     "item_total": {
//                         "currency_code": "USD",
//                         "value": "8634.00"
//                     },
//                     "shipping": {
//                         "currency_code": "USD",
//                         "value": "0.00"
//                     },
//                     "handling": {
//                         "currency_code": "USD",
//                         "value": "0.00"
//                     },
//                     "insurance": {
//                         "currency_code": "USD",
//                         "value": "0.00"
//                     },
//                     "shipping_discount": {
//                         "currency_code": "USD",
//                         "value": "0.00"
//                     }
//                 }
//             },
//             "payee": {
//                 "email_address": "sb-lqsvv26167108@business.example.com",
//                 "merchant_id": "QLDUU5TV893KW"
//             },
//             "description": "Order description",
//             "soft_descriptor": "PAYPAL *TEST STORE",
//             "items": [
//                 {
//                     "name": "dsdsds",
//                     "unit_amount": {
//                         "currency_code": "USD",
//                         "value": "233.00"
//                     },
//                     "tax": {
//                         "currency_code": "USD",
//                         "value": "0.00"
//                     },
//                     "quantity": "1",
//                     "description": "1020",
//                     "category": "DIGITAL_GOODS"
//                 },
//                 {
//                     "name": "dsfafdf",
//                     "unit_amount": {
//                         "currency_code": "USD",
//                         "value": "4134.00"
//                     },
//                     "tax": {
//                         "currency_code": "USD",
//                         "value": "0.00"
//                     },
//                     "quantity": "1",
//                     "description": "1022",
//                     "category": "DIGITAL_GOODS"
//                 },
//                 {
//                     "name": "avdvdqff",
//                     "unit_amount": {
//                         "currency_code": "USD",
//                         "value": "4267.00"
//                     },
//                     "tax": {
//                         "currency_code": "USD",
//                         "value": "0.00"
//                     },
//                     "quantity": "1",
//                     "description": "1024",
//                     "category": "DIGITAL_GOODS"
//                 }
//             ],
//             "shipping": {
//                 "name": {
//                     "full_name": "SUBHAM Das"
//                 },
//                 "address": {
//                     "address_line_1": "306 HARSHAPRIYA APARTMENT, FISHERYLANE, CHINTAMANIWAR",
//                     "admin_area_2": "BHUBANESWAR",
//                     "admin_area_1": "ON",
//                     "postal_code": "K1A 0B1",
//                     "country_code": "CA"
//                 }
//             },
//             "payments": {
//                 "captures": [
//                     {
//                         "id": "7WA29196JX9285406",
//                         "status": "COMPLETED",
//                         "amount": {
//                             "currency_code": "USD",
//                             "value": "8634.00"
//                         },
//                         "final_capture": true,
//                         "seller_protection": {
//                             "status": "NOT_ELIGIBLE"
//                         },
//                         "create_time": "2024-01-17T12:17:59Z",
//                         "update_time": "2024-01-17T12:17:59Z"
//                     }
//                 ]
//             }
//         }
//     ],
//     "payer": {
//         "name": {
//             "given_name": "SUBHAM",
//             "surname": "Das"
//         },
//         "email_address": "subhamdas2105@gmail.com",
//         "payer_id": "LTAC4JXT82CVN",
//         "address": {
//             "country_code": "CA"
//         }
//     },
//     "create_time": "2024-01-17T12:16:18Z",
//     "update_time": "2024-01-17T12:17:59Z",
//     "links": [
//         {
//             "href": "https://api.sandbox.paypal.com/v2/checkout/orders/70S48848NW191334T",
//             "rel": "self",
//             "method": "GET"
//         }
//     ]
// }
// const responseObject={
//   "id": "0Y7834960L260530N",
//   "intent": "CAPTURE",
//   "status": "COMPLETED",
//   "purchase_units": [
//       {
//           "reference_id": "default",
//           "amount": {
//               "currency_code": "USD",
//               "value": "10.00"
//           },
//           "payee": {
//               "email_address": "sb-lqsvv26167108@business.example.com",
//               "merchant_id": "QLDUU5TV893KW"
//           },
//           "soft_descriptor": "PAYPAL *TEST STORE",
//           "shipping": {
//               "name": {
//                   "full_name": "SUBHAM Das"
//               },
//               "address": {
//                   "address_line_1": "306 HARSHAPRIYA APARTMENT, FISHERYLANE, CHINTAMANIWAR",
//                   "admin_area_2": "BHUBANESWAR",
//                   "admin_area_1": "CO",
//                   "postal_code": "K1A 0B1",
//                   "country_code": "CA"
//               }
//           },
//           "payments": {
//               "captures": [
//                   {
//                       "id": "8JW31018LT2682549",
//                       "status": "COMPLETED",
//                       "amount": {
//                           "currency_code": "USD",
//                           "value": "10.00"
//                       },
//                       "final_capture": true,
//                       "seller_protection": {
//                           "status": "NOT_ELIGIBLE"
//                       },
//                       "create_time": "2023-11-06T15:48:14Z",
//                       "update_time": "2023-11-06T15:48:14Z"
//                   }
//               ]
//           }
//       }
//   ],
//   "payer": {
//       "name": {
//           "given_name": "SUBHAM",
//           "surname": "Das"
//       },
//       "email_address": "subhamdas2105@gmail.com",
//       "payer_id": "X2RZNLR3345WU",
//       "address": {
//           "country_code": "CA"
//       }
//   },
//   "create_time": "2023-11-06T15:42:45Z",
//   "update_time": "2023-11-06T15:48:14Z",
//   "links": [
//       {
//           "href": "https://api.sandbox.paypal.com/v2/checkout/orders/0Y7834960L260530N",
//           "rel": "self",
//           "method": "GET"
//       }
//   ]
// }

// 4399 9200 0664 7853
// 05/27
// 842
// K1A 0B1
// 416-555-1234