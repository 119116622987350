import React from 'react'
import '../../Styles/Privacypolicy/PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <>
    <div className='privacy_policy' >
    <h2>Privacy Policy</h2>
       <p>
       This privacy policy has been compiled to better serve those who are concerned with how their
‘Personally identifiable information’ (PII) is being used online. PII, as used in US privacy law and
information security, is information that can be used on its own or with other information to identify,
contact, or locate a single person, or to identify an individual in context. Please read our privacy
policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your
Personally Identifiable Information in accordance with our website.
       </p>
       <p className='question' >What personal information do we collect from the people that visit our blog, website or app?</p>
       <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name,
email address, phone number, credit card information or other details to help you with your
experience.
</p>
<p className='question'>
When do we collect information?
</p>
<p>
We collect information from you when you register on our site, place an order or enter information on
our site.

</p>
<p className="question">
How do we use your information?
</p>
<p>
We may use the information we collect from you when you register, make a purchase, sign up for our
newsletter, respond to a survey or marketing communication, surf the website, or use certain other
site features in the following ways:

<ul>
    <li> To personalize user’s experience and to allow us to deliver the type of content and product
offerings in which you are most interested.
</li>
    <li>To improve our website in order to better serve you.
</li>
    <li> To allow us to better service you in responding to your customer service requests.
</li>
    <li> To quickly process your transactions.
</li>
    <li>To ask for ratings and reviews of services or products
</li>
    <li><b>We only take name, email and profile picture that are publicly available.</b>  
</li>
<li>
<b>We do not collect any sensitive information. We only use name and email to register a user and provide them cart facility.</b>
</li>
</ul>
</p>
<p className="question">
How do we protect visitor information?

</p>
<p>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to
make your visit to our site as safe as possible.
</p>
<p>We use regular Malware Scanning.
</p>
<p>Your personal information is contained behind secured networks and is only accessible by a limited
number of persons who have special access rights to such systems, and are required to keep the
information confidential. In addition, all sensitive/credit information you supply is encrypted via
Secure Socket Layer (SSL) technology.
</p>
<p>
We implement a variety of security measures when a user places an order to maintain the safety of
your personal information.

</p>
<p>
All transactions are processed through a gateway provider and are not stored or processed on our
servers.

</p>
<p>
Third-party disclosure
We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
Third-party links
We do not include or offer third-party products or services on our website.

</p>
<p className='heading' >COPPA (Children Online Privacy Protection Act)</p>
<p>When it comes to the collection of personal information from children under 13, the Children’s Online
Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation’s
consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites
and online services must do to protect children’s privacy and safety online.</p>
<p>We do not specifically market to children under 13.</p>
<p className="heading">
Fair Information Practices</p>
<p>The Fair Information Practices Principles form the backbone of privacy law in the United States and
the concepts they include have played a significant role in the development of data protection laws
around the globe. Understanding the Fair Information Practice Principles and how they should be
implemented is critical to comply with the various privacy laws that protect personal information.</p>
<p>In order to be in line with Fair Information Practices we will take the following responsive action,
should a data breach occur:</p>
<p>We will notify the users via email
<ul>
    <li>Within 7 business days</li>
</ul> </p>
<p>
We also agree to the Individual Redress Principle, which requires that individuals have a right to
pursue legally enforceable rights against data collectors and processors who fail to adhere to the
law. This principle requires not only that individuals have enforceable rights against data users, but
also that individuals have recourse to courts or government agencies to investigate and/or prosecute
non-compliance by data processors.</p>
<p className="heading">CAN SPAM Act
</p>
<p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for
commercial messages, gives recipients the right to have emails stopped from being sent to them,
and spells out tough penalties for violations.
</p>
<p>
We collect your email address in order to:
<ul>
    <li>Process orders and to send information and updates pertaining to orders.
</li>
<li>We may also send you additional information related to your product and/or service.
</li>
</ul> 
</p>
<p>
To be in accordance with CANSPAM we agree to the following:

</p>
<p>If at any time you would like to unsubscribe from receiving future emails, you can email us at
contactsmurfelite@gmail.com and we will promptly remove you from ALL correspondence.

</p>

    
<h2>DELIVERY METHOD AND TIME</h2>
<p>Products are sent as soon as possible via e-mail (the one which the end user registers on the
website) after processing the order. The estimated delivery time is one minute to twenty-four hours
submitting the order in the system. If the order delivery is delayed once five minute period has
passed, the customer should contact our customer service by live chat or e-mail.
</p>
<h2>PRODUCTS
</h2>
<p>You are responsible for any fees, taxes or other costs associated with the purchase and delivery of
your items resulting from charges imposed by your relationship with payment services providers or
the duties and taxes imposed by your local customs officials or other regulatory body. All products
are virtual game’s accounts. The currency is USD. Therefore, there is no shipping costs or taxes on
the website.
</p>
    </div>

</>
  )
}

export default PrivacyPolicy