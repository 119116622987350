const regularToSnakeCase =(text)=>{
  if(!text) return text
    return text.replace(/\s+/g, '_').toLowerCase();
}

const capitalizeInitials=(inputString)=> {
  // Split the input string into words
  const words = inputString.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word;
    }
  });

  // Join the words back into a single string
  const resultString = capitalizedWords.join(' ');

  return resultString;
}

export { regularToSnakeCase , capitalizeInitials}