import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import React, { useContext } from "react";
import { loginUser } from "../../util/LoginFunctions";
import { userContext } from "../../DataLayer/UserContext";
import { api } from "../../util/Apis";
import { loginContext } from "../../DataLayer/IsLogin";
import Toast from "../../DataLayer/Toast";
import { capitalizeInitials } from "../../util/StringManipulation";

const GoogleOAuth = ({ setIsProcessing}) => {
    const setUserDetails = useContext(userContext).setUserDetails
    const {loginVisibility, setLoginVisibility} = useContext(loginContext)
    const {toast} = useContext(Toast)
  const onSuccess = async(credentialResponse) => {
    setIsProcessing(val=>true)
    let credentials = jwtDecode(credentialResponse.credential);
    let email = credentials.email;
    let name = credentials.given_name+" "+credentials.family_name;
    name=capitalizeInitials(name)
    try {
        let res = await api("POST", "googleOAuth", {
        name,
        email,
    })
    if(res && res.status===201){
        loginUser(setUserDetails,{email,name})
        setLoginVisibility(false)
        toast("Login Successful.", "SUCCESS", 1500)
    }
    } catch (error) {
        toast("Login Failed.", "DANGER", 1500)
    }
    
    setIsProcessing(val=>false)
    
  };

  const onError = () => {
     toast("Login Failed.", "DANGER", 1500)
  };

  return <GoogleLogin onSuccess={onSuccess} onError={onError} />;
};

export default GoogleOAuth;
