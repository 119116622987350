import React, { useState } from 'react'
import { userContext, userDetails } from "../../DataLayer/UserContext";
import { userCartDetails,userCartContext } from "../../DataLayer/CartDetails";
import { loginDetails,loginContext } from "../../DataLayer/IsLogin";

const PaymentContext = React.createContext()

const Provider = ({children}) => {
    const [userState, setUserState] = useState(userDetails);
    const [userCart, setUserCart] = useState([])
    const [changeListenerToCart, setChangeListenerToCart] = useState(true)
    const [loginVisibility, setLoginVisibility] = useState(false)
    const [refreshUserListener, setRefreshUserListener] = useState(false)
    const [paymentStatusObject, setPaymentStatusObject] = useState({
        visibility: false,
        message: 'Demo text'
    })
    const reloadCart=()=>{
        setChangeListenerToCart(val=>!val)
    }
    const setUserDetails=(name,email,sessionId)=>{
        setUserState(val=>{return {...val,name:name,email:email,sessionId}})
    }
    const setVerification=(bool)=>{
        setUserState(val=>{return {...val, isVerified:bool}})
    }
    const setUserCartDetails=(arr)=>{
        setUserCart(val=>arr)
    }
    const refreshUser=()=>{
        setRefreshUserListener(val=>!val)
    }
    return(
        <userContext.Provider  value={{refreshUserListener,refreshUser,userState,setUserDetails,setVerification}}>
        <loginContext.Provider value={{loginVisibility, setLoginVisibility}}>
        <userCartContext.Provider value={{userCart,setUserCartDetails, reloadCart, changeListenerToCart}}>
        <PaymentContext.Provider value={{paymentStatusObject, setPaymentStatusObject}}>
            {children}
        </PaymentContext.Provider>
        </userCartContext.Provider>
        </loginContext.Provider>
        </userContext.Provider>
    )
}

export default Provider
export {PaymentContext}