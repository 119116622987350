const GAME_STRUCTURE = {
    cs2 : [
        "name",
        "Description",
        "Hours Played",
        // "CS2 Rank",
        "Medals",
        "Coins",
        // "Skins",
        "Steam Market Enabled",
        "Can Add Friends",
        "Account Status",
        "Competitive Bans",
        "VAC Status",
        "Included Games",
        "You Will Receive",
        "Delivery Status",
        "Account Region",
        "Note"
    ],

    valorant: [
        "name",
        "Description",
        "Riot ID",
        "Account Level",
        "Valorant Rank",
        "Rank Ready",
        "Skins",
        "Can Add Friends",
        "Account Status",
        "Competitive Bans",
        "Riot Ban",
        "Included Games",
        "You Will Receive",
        "Delivery Status",
        "Account Region",
        "Note"
    ],
    gtav:[
        "name",
        "Description",
        "GTA Money",
        "Properties",
        "Vehicles",
        "Western, Clothing & Tattoos",
        "Account Rank",
        "Starter Pack",
        "Can Add Friends",
        "Account Status",
        "Included Games",
        "You Will Receive",
        "Delivery Status",
        "Note"
    ],
}


const getProductStructure=(game)=>{
    return GAME_STRUCTURE[game];
}

export {getProductStructure}

