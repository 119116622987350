import React, { useState } from 'react'
import Toast from '../../DataLayer/Toast'
import '../../Styles/Toast/Toast.css'

const ToastContainer = ({children}) => {
    const [toastVisibility, setToastVisibility] = useState(false)
    const [toastMessage, setToastMessage] = useState('Toast Message here')
    const [timeoutObject, setTimeoutObject] = useState(undefined)
    const [toastStatus, setToastStatus] = useState("SUCCESS")
    const toast = (message , status="SUCCESS" , duration= 2000)=>{
        clearTimeout(timeoutObject);
        setToastStatus(status)
        setToastVisibility(true)
        setToastMessage(message)
        const timeout1 = setTimeout(() => {
            setToastVisibility(false)
            setToastMessage('')
        }, duration);
        setTimeoutObject(timeout1);
    }
    let toastObject = {
        "SUCCESS" : <>{toastVisibility ? <ToastSuccess toastMessage={toastMessage} />: <></>}</> ,
        "DANGER" : <>{toastVisibility ? <ToastDanger toastMessage={toastMessage} />: <></>}</> ,
        "WARNING" : <>{toastVisibility ? <ToastWarning toastMessage={toastMessage} />: <></>}</> ,
        "CUSTOM" : <>{toastVisibility ? <ToastCustom toastMessage={toastMessage} />: <></>}</> ,
        
    }
    const ToastElement = ()=>{
        return toastObject[toastStatus]
    }
  return (
    <Toast.Provider value={{toast,toastVisibility, toastMessage}} >
        {children}
        <ToastElement/>
        {/* <section id="toast_test" 
            onClick={e=> toast("Toast Message Here","DANGER")}
        >Toast  test  </section> */}
    </Toast.Provider>
  )
}

export default ToastContainer

const ToastSuccess = ({toastMessage}) =>{
    return <section id='toast' className='toast_success'>
    <a target="_blank" href="https://icons8.com"><section></section></a>
    {toastMessage}
    </section>
}

const ToastDanger = ({toastMessage}) =>{
    return <section id='toast' className='toast_danger'>
    <a target="_blank" href="https://icons8.com"><section></section></a>
    {toastMessage}
    </section>
}

const ToastWarning = ({toastMessage}) =>{
    return <section id='toast' className='toast_warning'>
    <a target="_blank" href="https://icons8.com"><section></section></a>
    {toastMessage}
    </section>
}

const ToastCustom = ({toastMessage}) =>{
    return <section id='toast' className='toast_custom'>
    <a target="_blank" href="https://icons8.com"><section></section></a>
    {toastMessage}
    
    </section>
}



