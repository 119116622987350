import axios from "axios";
const axios_post=async(url,object,header)=>{
    return await axios.post(url,object,header);
}

const axios_get=async(url,object,header)=>{
    return await axios.get(url,object,header);
}

const axios_delete=async(url,object,header)=>{
    return await axios.delete(url,object,header);
}

const axios_put=async(url,object,header)=>{
    return await axios.put(url,object,header);
}
const api=async(method, endpoint , object)=>{
    // let BASE_URL="http://localhost:3001/api/"
    let BASE_URL="https://smurfelite.com/api/"
    let header={
        headers: {
            'Access-Control-Allow-Origin': 'https://smurfelite.com/', 
        //     'Access-Control-Allow-Origin': 'http://localhost:3000', 
            
        },
        withCredentials: true
    }
    let axiosObject={
        "POST": axios_post,
        "GET": axios_get,
        "DELETE": axios_delete,
        "PUT" : axios_put
    }
    method=method.toUpperCase();
    return await axiosObject[method](BASE_URL+endpoint,object,header);
}

export {api}