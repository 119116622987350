import { createContext } from "react";

const userContext = createContext();

const userDetails= {
    name : undefined,
    email : undefined,
    isVerified: false
}

export {userContext , userDetails} 