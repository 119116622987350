import React, { useContext, useEffect, useState } from "react";
import "../../Styles/Navbar/Navbar_lg.css";
import "../../Styles/Navbar/Navbar_mobile.css";
import "../../Styles/Navbar/Navbar_fixed.css";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { FaTwitter, FaFacebookF } from "react-icons/fa";
import { AiFillInstagram, AiOutlineWechat } from "react-icons/ai";
import { SiPinterest, SiShopify } from "react-icons/si";
import { IoReorderThree } from "react-icons/io5";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { BiSolidMessageDetail } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { userContext } from "../../DataLayer/UserContext";
import { loginContext } from "../../DataLayer/IsLogin";
import { logOut, showLogin } from "../../util/LoginFunctions";
import { userCartContext } from "../../DataLayer/CartDetails";
import Toast from "../../DataLayer/Toast";

const SITE_NAME = "SmurfElite";

const Navbar = () => {
  const { name, email } = useContext(userContext).userState;
  const setUserDetails = useContext(userContext).setUserDetails;
  const loginCObject = useContext(loginContext);
  const cart = useContext(userCartContext).userCart;
  const { toast } = useContext(Toast);
  const setUserCart = useContext(userCartContext).setUserCartDetails;
  return (
    <>
      <NavbarFixed
        user={{ name, email }}
        toast={toast}
        cartCount={cart.length}
        setUserCart={setUserCart}
        setUserDetails={setUserDetails}
        loginContext={loginCObject}
      />
      <NavbarLarge
        user={{ name, email }}
        toast={toast}
        cartCount={cart.length}
        setUserCart={setUserCart}
        setUserDetails={setUserDetails}
        loginContext={loginCObject}
      />
      <NavbarMobile
        user={{ name, email }}
        toast={toast}
        cartCount={cart.length}
        setUserCart={setUserCart}
        setUserDetails={setUserDetails}
        loginContext={loginCObject}
      />
    </>
  );
};

export default Navbar;

const NavbarFixed = ({
  user,
  toast,
  loginContext,
  setUserDetails,
  cartCount,
  setUserCart,
}) => {
  const [scrollY, setScrollY] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position from the top
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className="nav_fixed"
      id="nav_fixed"
      style={{ top: scrollY > 120 ? 0 : "-100%" }}
    >
      <section className="navbar">
        <section
          className="logo col-3 pointer"
          onClick={(e) => {
            navigate("/");
            scrollToTop("nav_fixed");
          }}
        >
          <section className="logo_img"></section>
          {SITE_NAME}
        </section>

        <section className="nav_links col-5">
          <span>
            <Link to="/">Home</Link>
          </span>
          <span>
            <Link to="/" onClick={(e) => scrollToAbout()}>
              About
            </Link>
          </span>
          <span>
            <Link to="/games">Games</Link>
          </span>
          <span>
            <Link to="/" onClick={(e) => scrollToCountactUs()}>
              Contact
            </Link>
          </span>
        </section>

        <section className="user_info col-4 ">
          {user.email === undefined ? (
            <span
              style={{ cursor: "pointer", minWidth: "160px" }}
              onClick={(e) => showLogin(loginContext)}
            >
              User Login
            </span>
          ) : (
            <span>
              User {user.name}
              <span className="">
                <FaAngleDown />
              </span>
              <div
                className="logout"
                onClick={(e) => logOut(setUserDetails, setUserCart, toast)}
              >
                Logout
              </div>
              <div className="my_orders" onClick={(e) => navigate("/orders")}>
                My Orders
              </div>
            </span>
          )}
          <span>
            <div className="count">{cartCount}</div>
            <Link to={"/cart"}>
              {" "}
              <SiShopify />
            </Link>
          </span>
        </section>
      </section>
    </nav>
  );
};

const NavbarMobile = ({
  user,
  toast,
  loginContext,
  setUserDetails,
  cartCount,
  setUserCart,
}) => {
  const [collapse, setCollapse] = useState(true);
  const navigate = useNavigate();
  return (
    <nav className="nav_mobile" id="nav_mobile">
      <div className="navbar_mobile_upper">
        <section
          className="logo col-6"
          onClick={(e) => {
            navigate("/");
            scrollToTop("nav_mobile");
          }}
        >
          <section className="logo_img"></section>
          <span>{SITE_NAME}</span>
        </section>

        <span
          className="nav_mob_collapse"
          onClick={(e) => setCollapse((val) => !val)}
        >
          <IoReorderThree />
        </span>
      </div>
      <div
        className="navbar_mobile_lower"
        style={{ height: collapse ? "0vh" : "42vh" }}
      >
        <section className="nav_links col-5">
          <div>
            <Link to="/">Home</Link>
          </div>
          <div>
            <Link to="/games">Games</Link>
          </div>
          <div>
            <Link to="/cart">Cart</Link>
          </div>
          <div>
            <Link to="/orders">My orders</Link>
          </div>
          <div>
            <Link to="/" onClick={(e) => scrollToCountactUs()}>
              Contact
            </Link>
          </div>
          <div>
            {user.email === undefined ? (
              <a onClick={(e) => showLogin(loginContext)}>User Login</a>
            ) : (
              <a onClick={(e) => logOut(setUserDetails, setUserCart, toast)}>
                Log out
              </a>
            )}
          </div>
        </section>
      </div>
    </nav>
  );
};

const NavbarLarge = ({
  user,
  toast,
  loginContext,
  setUserDetails,
  cartCount,
  setUserCart,
}) => {
  const navigate = useNavigate();
  return (
    <nav className="nav_lg" id="nav_large">
      <div className="navbar navbar_lower">
        <section
          className="logo col-3"
          onClick={(e) => {
            navigate("/");
            scrollToTop("nav_large");
          }}
        >
          <section className="logo_img pointer"></section>
          {SITE_NAME}
        </section>

        <section className="nav_links col-5">
          <span>
            <Link to="/">Home</Link>
          </span>
          <span>
            <Link to="/" onClick={(e) => scrollToAbout()}>
              About
            </Link>
          </span>
          <span>
            <Link to="/games">Games</Link>
          </span>
          <span>
            <Link to="/" onClick={(e) => scrollToCountactUs()}>
              Contact
            </Link>
          </span>
        </section>

        <section className="user_info col-4 ">
          {user.email === undefined ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => showLogin(loginContext)}
            >
              User Login
            </span>
          ) : (
            <span>
              User {user.name}
              <span className="">
                <FaAngleDown />
              </span>
              <div
                className="logout"
                onClick={(e) => logOut(setUserDetails, setUserCart, toast)}
              >
                Logout
              </div>
              <div className="my_orders" onClick={(e) => navigate("/orders")}>
                My Orders
              </div>
            </span>
          )}
          <span>
            <div className="count">{cartCount}</div>
            <Link to={"/cart"}>
              {" "}
              <SiShopify />
            </Link>
          </span>
        </section>

        <section className="col-1"></section>
      </div>
    </nav>
  );
};

const scrollToAbout = () => {
  // Scroll to the #about section after a short delay
  setTimeout(() => {
    const aboutSection = document.getElementById("about");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  }, 100);
};
const scrollToCountactUs = () => {
  // Scroll to the #contact_us section after a short delay
  setTimeout(() => {
    const aboutSection = document.getElementById("contact_us");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  }, 100);
};

const scrollToTop = (id) => {
  // Scroll to the #navbar section after a short delay
  setTimeout(() => {
    const navbar = document.getElementById("nav_large");
    if (!navbar) document.getElementById("nav_mobile");
    if (navbar) {
      navbar.scrollIntoView({ behavior: "smooth" });
    }
  }, 100);
};
