export const sliderData = [
    {
      image: ('../assets/carousel/carousel_valorant.jpg'),
      heading: "VALORANT",
      game:"valorant",
      desc: "Unlock new gaming horizons with our ValorantAccountsHub. Discover handpicked Valorant accounts, tailored to your playstyle and skill level. Elevate your gameplay today!",
    },
    {
      image: require('../assets/carousel/carousel_cs2.png'),
      heading: "CS 2",
      game:"cs2",
      desc: "Unlock a new level of CS:GO excitement with our verified smurf accounts. Explore diverse ranks and dominate the game from a fresh perspective!",
    },
    {
      image: require('../assets/carousel/carousel_gtav.png'),
      heading: "Grand Theft Auto V",
      game:"gtav",
      desc: "Unlock boundless adventures with premium GTA V accounts from our platform. Immerse yourself in the dynamic world of Los Santos like never before.",
    },
  ];