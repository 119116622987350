import React from 'react'
import '../../Styles/Footer/Footer.css'
import instagram from '../../assets/footer/instagram.svg'
import facebook from '../../assets/footer/facebook.svg'
import twitter from '../../assets/footer/twitter.svg'
import {HiClipboard} from 'react-icons/hi'
import {TbClipboardCheck} from 'react-icons/tb'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/smurf_elite_logo.png'


const Footer = () => {
    const [textCopied, setTextCopied] = useState(false)
  return (
    <footer id='footer' className="w-100 py-4 flex-shrink-0  footer2" >
    <div className="container py-4">
        <div className="row gy-4 gx-5">
            <div className="col-lg-4 col-md-6">
                <img src={logo} alt="" style={{width:"100%"}} className="image-fluid footer_logo"></img>
                <p className="small "><strong>From beginner tips to expert guides, SmurfElite is your ultimate gaming resource.</strong></p>
                <p className="small  mb-0">&copy; Copyrights. All rights reserved. </p>
            </div>
            <div className="col-lg-2 col-md-6">
                <h5 className="text-white mb-3">Quick links</h5>
                <ul className="list-unstyled ">
                    <li hover="0.2"><Link to={'/'}>Home</Link></li>
                    <li hover="0.2"><Link to={'/orders'} >Orders</Link></li>
                    <li hover="0.2"><Link to={'/privacy-policy'} >Privacy Policy</Link></li>
                </ul>
            </div>
            <div className="col-lg-2 col-md-6">
                <h5 className="text-white mb-3"> Games</h5>
                <ul className="list-unstyled ">
                    <li hover="0.2"><Link to={'/game'}>CS GO</Link></li>
                    <li hover="0.2"><Link to={'/game'}>Valorant</Link></li>
                    <li hover="0.2"><Link to={'/game'}>GTA V</Link></li>
                    {/* <li><a href="">FAQ</a></li> */}
                </ul>
            </div>
            <div className="col-lg-4 col-md-6">
                <h5 className="text-white mb-3">Social Media</h5>
                {/* <p className="small ">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p> */}
                <div style={{display:"flex" , gap:"20px" }}>

                <img instagram="" alt='' onClick={e=>redirectToSocialMedia('instagram')} src={instagram} width="25px" height="25px"></img>
                <img facebook="" alt='' onClick={e=>redirectToSocialMedia('facebook')} src={facebook} width="25px" height="25px"></img>
                <img twitter='' alt='' onClick={e=>redirectToSocialMedia('twitter')} src={twitter} width="25px" height="25px"></img>
                </div>
                <p className="" style={{marginBottom:"0", marginTop:"30px"}}>For any queries kindly contact</p>
                <p className="" >help@smurfelite.com  &nbsp;
                    {textCopied?
                    <TbClipboardCheck/>
                    :
                    <HiClipboard onClick={e=>clipboard(setTextCopied)}/>
                    }
                </p>

                
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer

const clipboard= (setTextCopied)=>{
    const textToCopy = 'help@smurfelite.com'; // Replace with the actual text you want to copy
  navigator.clipboard.writeText(textToCopy)
    .then(() => {
      setTextCopied(true)
      setTimeout(() => {
      setTextCopied(false)
        
      }, 5000);
    })
    .catch((error) => {
      
    });
}

const redirectToSocialMedia=(site)=>{
    switch (site) {
        case 'instagram':
            window.open('https://www.instagram.com/smurf.elite/', '_blank');
            break;
        case 'facebook':
            break;
        case 'twitter':
            break;
        default:
            break;
    }
    
}