import React from 'react'

const ReturnPolicy = () => {
  return (
    <div className='return-policy' >
        <h2>RETURNS AND REFUNDS POLICY</h2>
        <p>
        We shall not issue refunds for ranked accounts in the following condition:
        <ol>
            <li> Any account purchased from us and VAC banned due to cheats or any other illegal activity use by
the end-user will not be entertained by smurfelite.com</li>
            <li>smurfelite.com will not be responsible for the accounts sell by the end-user to any other person.
</li>
            <li>Any individual try to scam or cheat with Buy A CS2 Smurf will be banned from our services and
earlier accounts purchase from us will be confiscated. We recommend contacting us for assistance if
you experience any issues receiving or using our accounts.</li>
            <li>We do not issue any refunds in case of any fraudulent activity (such as stolen cards) as our
products are digital goods and once delivered they cannot be taken back.
</li>
        </ol> 
        </p>
        <p>Where possible, we will work with you and/or any user selling on our website, to resolve any
disputes arising from your purchase.</p>
<p>
For customer service inquiries or disputes, You may contact us by email at <b> contactsmurfelite@gmail.com</b>.
</p>
    </div>
  )
}

export default ReturnPolicy