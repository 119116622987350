import {api} from './Apis'
import { regularToSnakeCase } from './StringManipulation'
let array = [
    ["cs2",'Counter Strike 2'],
    ['valorant','Valorant'],
    ['gtav','GTA V']
]
const getGame=async(game , filter , sort ,order, currentPage)=>{
    let res = await api('GET','filtergames',{
        params:{
            game :  getDatabaseName(game) , 
            filter,
            sort : regularToSnakeCase(sort),
            order,
            page:currentPage
    }
    }).catch(err=> {})
    if(res===undefined || res.data?.data===undefined) return {data:[]}
    return res.data;
    
}

const getDatabaseName=(name)=>{
    let result = undefined
    array.forEach(arr=>{
        arr.forEach(text=>{
            if(text.toLowerCase()===name.toLowerCase()){
                result= arr[0]
            }
        })
    })
    return result
}

const gameFilter = {
    cs2 : [
        'Low-Tier Accounts', //1-2 Medals
        'Mid-Tier Accounts', // 3-4 Medals
        'Premium-Tier Accounts', // 5+ medals
    ],
    valorant : [
        'Ranked Ready Accounts',
        'Premium-Tier Accounts',
        'North America Region',
        'Europe Region',
        'Oceania Region',
        'Any Region'
    ],
    gtav : [
        'Criminal Enterprice Starter Pack',
        'XBOX',
        'Play Station',
        'PC'
    ],
    dota2:[
        'Ranked Enabled'
    ]
}

export {getGame, getDatabaseName , gameFilter}