 import gtav from '../assets/GamePage/gtav.webp';
 import valorant from '../assets/GamePage/valorant.webp';
 import csgo from '../assets/GamePage/csgo.png';
 const constants = {
    orders: {
        session:{
            ORDER_ID_KEY: 'orders_orderId'
        }
    },
    photo:{
        default: (game)=>{
            switch (game) {
                case 'csgo':
                    return csgo;
                case 'valorant':       
                    return valorant;
                case 'gtav':
                    return gtav;         
                default:
                    return '';
            }
        }
    },
    toast:{
        STATUS_TEXT:{
            SUCCESS: 'SUCCESS',
            // ERROR: 'ERROR',
            WARNING: 'WARNING',
            DANGER: 'DANGER',
            CUSTOM : 'CUSTOM'

        }
    }
}

export default constants;