import React, { useContext, useEffect, useLayoutEffect, useState  } from 'react'
import '../../Styles/Orders/Orders.css'
import constants from '../../util/Constants';
import { api } from '../../util/Apis';
import { userContext } from '../../DataLayer/UserContext';
import { FaCircle } from "react-icons/fa";
import Toast from '../../DataLayer/Toast';

const OrdersPage = () => {
  const [ordersState, setOrdersState] = useState([])
  const user = useContext(userContext).userState;
  useEffect(() => {
    if(user.email === undefined) return
    getOrders();
  },[user])

  const getOrders = async () => {
    try {
      const res = await api('POST', 'orders', {
        email : user.email
      });
      setOrdersState(res.data);
    } catch (error) {
    }
  }

  return (
    <div className='orders_container'>
       <section className="orders">
          {ordersState.map((data, index) => {
            return <OrderCard ordersArray={data.products} orderId={data.order_id}  key={index+"order_card"} />
          })}
          {ordersState.length === 0 && <div className="no_orders">No Orders</div>}
    </section>
    </div>
  )
}

const OrderCard = ({ordersArray, orderId}) => {
  const [sendingCreds, setSendingCreds] = useState(false)
  const user = useContext(userContext).userState;
    const {toast} = useContext(Toast)
  const resendCredentials = async (productId) => {
    if(sendingCreds) {
      toast('Request already in progress', constants.toast.STATUS_TEXT.WARNING,2000 )
      return
    }
    setSendingCreds(true)
    toast('Preparing Credentials to send', constants.toast.STATUS_TEXT.WARNING, 1500)
    try {
      const res = await api('POST', 'resendcredentials', {
        email : user.email,
        productId : [productId],
        orderId : orderId
      });
      setTimeout(() => {
        toast(`Credentials sent ${user.email}`, constants.toast.STATUS_TEXT.SUCCESS, 3000)
      }, 1000);
      // setTimeout(() => {
        setSendingCreds(false)
      // }, 1000*60*2);
    }
    catch(error) {
      toast("Failed to send credentials",constants.toast.STATUS_TEXT.DANGER, 3000 )
      setSendingCreds(false)
    }
    
  }
  return(
    <section className='order_card'>
       {ordersArray?.map((data, index) => {
          return(
            <section className="sub_order" key={"sub_order"+index+""}>
                <span className="picture" 
                      style={{  
                        backgroundImage: data.image?`url(${data.image})`
                            :constants.photo.default(data.game) 
                        }} >
                 </span>
                <span className="name">{data.name}</span>
                <span className="price">${data.price}</span>
                <section className="date_section">
                    <span className="date"><FaCircle /> Delivered on <b>{data.date?data.date:new Date().toLocaleDateString()}</b> </span>
                    <span>Your item has been delivered</span>
                    <span className="help">Need help?</span>
                    <span  className="help" onClick={()=>resendCredentials(data.product_id)} >{sendingCreds ? "Sending" : "Re-send"} Credentials

                    </span>
                </section>
          </section>
          )
       })}
    </section>
  )
}


export default OrdersPage
