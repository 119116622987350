import { api } from "./Apis"

const addToCart=async(userCart,setUserCart, productId,email,toast)=>{
    
    if(email===undefined){
        toast("Please login to add items to cart.","WARNING",1500)
        return
    }
    if(userCart.includes(productId)){
        toast('Product already added to cart',"CUSTOM",1500)
        return
    }
    let res = await api("POST",'addtocart',{
        email, productId
    }).catch(err=>{})
    toast("Unable to add item to your cart.","DANGER",1500)
    if(res===undefined)return
    setUserCart([...userCart, productId])
    toast("Item has been added to your cart.","SUCCESS",1500)
}

const removeFromCart=(setUserCart, productId)=>{

}


export {addToCart , removeFromCart}