import React, { useContext, useEffect } from 'react'
import { loginUser } from '../../util/LoginFunctions';
import { userContext } from '../../DataLayer/UserContext';
import { api } from '../../util/Apis';
import { userCartContext } from '../../DataLayer/CartDetails';
import Toast from '../../DataLayer/Toast';

const AutoLogin = () => {
  const setUserDetails = useContext(userContext).setUserDetails
  const user = useContext(userContext).userState
  const { refreshUserListener } = useContext(userContext)
  const setCart = useContext(userCartContext).setUserCartDetails
  const { changeListenerToCart } = useContext(userCartContext)
  const { toast } = useContext(Toast)
  useEffect(() => {
    autoLogin()
  }, [refreshUserListener])
  async function autoLogin() {
    try {
      let res = await api('POST', 'autologin', {})
      .then(res => res.data)
    if (res === undefined) return
    loginUser(setUserDetails, res)
    toast("Login Successful.", "SUCCESS", 1500)
    } catch (error) {
    }
  }
  useEffect(() => {

    if (user.email === undefined || user.email === '') return
    loadCart()
  }, [user, changeListenerToCart])

  async function loadCart() {
try {
  let cart = await api('POST', 'cart', { email: user['email'] })
    if (cart == undefined || cart.data == undefined || cart.data.data == undefined) return
    setCart(cart.data.data);
  } catch (error) {
    toast("Failed to load cart", "DANGER", 1500)
  }
}
    

  return (
    <>
    </>
  )
}

export default AutoLogin