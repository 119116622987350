import React, { useContext, useState } from 'react'
import '../../Styles/Login/Login.css'
import { loginContext } from '../../DataLayer/IsLogin'
import { api } from '../../util/Apis'
import { loginUser } from '../../util/LoginFunctions'
import { userContext } from '../../DataLayer/UserContext'
import Toast from '../../DataLayer/Toast'
import GoogleOAuth from '../GoogleOAuth/GoogleOAuth'

const LoginRegister = () => {
  const setUserDetails = useContext(userContext).setUserDetails
  const {toast} = useContext(Toast)
  const {loginVisibility, setLoginVisibility} = useContext(loginContext)
  const [isProcessing, setIsProcessing] = useState(false)
  const [signUpForm, setSignUpForm] = useState({
     username : "",
     email:"",
     password:""
  })
  const [loginForm, setLoginForm] = useState({
    email:"",
    password:""
  })
  const [messsage, setMesssage] = useState("")
  const onChangeSignUp=(name,value)=>{
    let temp = {...signUpForm}
    temp[name]=value
    setSignUpForm(val=>temp)
  }
  const onChangeLogin=(name,value)=>{
    let temp = {...loginForm}
    temp[name]=value
    setLoginForm(val=>temp)
  }
  const showMessage=(text,time)=>{
    if(time==undefined)time=10000
    setMesssage(text)
    setTimeout(() => {
      setMesssage("")
    }, time);
  }
  const onSubmitSignUp=async (e)=>{
    e.preventDefault();
    setIsProcessing(val=>true)
    if(!checkInput(signUpForm))return;
    let object={
      name: signUpForm.username,
      email:signUpForm.email,
      password: signUpForm.password,
      country:"Not set"
    }
    let flag=true;
    let res = await api("POST","signup",object)
    .catch(err=>{
      let responseStatus= err.response.status;
      if(responseStatus===409){
          toast("User already exists","DANGER",3000)
      }
      flag=false
    })
    setIsProcessing(false)
    if(!flag)return
    if(res.status===201){
      // showMessage("Account created successfully. Please verify your email to enable login");
      toast("Please verify your account to enable login.","CUSTOM",5000)
    }
  }
  const onSubmitLogin=async (e)=>{
    e.preventDefault();   
    setIsProcessing(val=>true)
    await new Promise(resolve => setTimeout(resolve, 2000));
    if(!checkInput(loginForm))return; 
    let object={
      email:loginForm.email,
      password: loginForm.password
    }
    let isError=false;
    let res = await api("POST","auth",object)
    .catch(err=>{
      let responseStatus= err.response.status;
      if(responseStatus===404)
        showMessage("User does not exist.")
      else
      toast("Email/Password is wrong. Please verify the email address to enable login.","DANGER",3000)
      isError=true
      return undefined
    })
    setIsProcessing(false)
    if(isError)return
    if(res.status===200){
      let data = res.data;
      loginUser(setUserDetails,data)
      setLoginVisibility(false);
      toast("Login Successful.","SUCCESS",1500)
    }
    else showMessage("Email/Password is wrong. Please verify the email address to enable login.")
      
  }
  return (<>
    <div className="login_overlay" 
    style={{display:loginVisibility?"block":"none"}}
     onClick={e=>setLoginVisibility(false)}></div>
    <div className="login_container" style={{height:loginVisibility?"500px":"0px"}} >  	
		    <input type="checkbox" id="chk" aria-hidden="true"/>

			<div className="signup">
				<form onSubmit={e=>onSubmitSignUp(e)}>
					<label htmlFor="chk" aria-hidden="true">Sign up</label>
					<input type="text" onChange={e=>onChangeSignUp(e.target.name,e.target.value)} value={signUpForm.username} name="username" placeholder="User name" required/>
					<input type="email" onChange={e=>onChangeSignUp(e.target.name,e.target.value)} value={signUpForm.email} name="email" placeholder="Email" required/>
					<input type="password" onChange={e=>onChangeSignUp(e.target.name,e.target.value)} value={signUpForm.password} name="password" placeholder="Password" required/>
          <section style={{color:"white",margin:"auto"}}>{messsage}</section>
					<button disabled={isProcessing} type='submit'>
            {isProcessing? <section className='loading'></section>
                : "Sign up"
            }
            </button>
				</form>
			</div>

			<div className="login">
				<form onSubmit={e=>onSubmitLogin(e)}>
					<label htmlFor="chk" aria-hidden="true">Login</label>
					<input type="email" onChange={e=>onChangeLogin(e.target.name,e.target.value)} value={loginForm.email} name="email" placeholder="Email" required/>
					<input type="password" onChange={e=>onChangeLogin(e.target.name,e.target.value)} value={loginForm.password} name="password" placeholder="Password" required/>
          <section style={{color:"black",margin:"auto", textAlign:"center"}}>{messsage}</section>
          <button disabled={isProcessing} type='submit'>
              {isProcessing? <section className='loading'></section>
                    : "Login"
                }
            </button>
          <button className='external_login' disabled={isProcessing} ><GoogleOAuth isProcessing={isProcessing} setIsProcessing={setIsProcessing}  /></button>
				</form>
			</div>
	</div>

  </>
  )
}

export default LoginRegister

const checkInput=(object)=>{
   let keys= Object.keys(object);
   let flag= true
   keys.forEach(key=>{
    if(object[key]===undefined || object[key]=== null || object[key]==='')
        flag=false
   })
   return flag
}