import React from 'react'
import '../../Styles/GamePage/ProductImage.css'

const ProductImage = ({classList,game, data}) => {
  switch(game){
    case "cs2":
      return <CS2Image classList={classList} data={data} />;
    case "gtav":
      return <GTAVImage classList={classList} data={data} />;
    case "valorant":
      return <ValorantImage classList={classList} data={data} />;
    default:
      return <DefaultImage classList={classList} data={data} />;
  }
}

export default ProductImage

const CS2Image=({classList, data})=>{
    let str= data['medals']
    // let str = '2015, 2016, 2017, 2018, 2019,2020,2021,2022'
    let medalsArray = str.split(',').map((data) => data.trim());
    return <div className={`${classList} product_image icon_cs2 cs2_product_image`} >
        {medalsArray.slice(0,10).map((year,index)=>{
        
            return (
            <section 
                className={`medals medal_${year}` }
                key={"product_image_medals"+index}>
            </section>
        )})}
        <section className="hours_played">{data['hours_played']} HOURS </section>
        <section className="prime_accounts">PRIME ACCOUNTS </section>
    </div>
}

const GTAVImage=({classList, data})=>{
    return <div className={`${classList} icon_gtav  `}  >

    </div>
}

const ValorantImage=({classList, data})=>{
    return <div className={`${classList} icon_valorant`} >

    </div>
}

const DefaultImage=({classList, data})=>{
    return <div className={`${classList} `} >

    </div>
}