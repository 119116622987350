import React, { useContext, useEffect, useState } from 'react'
import '../../Styles/ProductPage/ProductPage.css'
import { FaHeart } from "react-icons/fa";
import { SiShopify } from 'react-icons/si';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDatabaseName } from '../../util/GameFunctions';
import { getProductStructure } from '../../util/ProductFunctions';
import { userContext } from '../../DataLayer/UserContext';
import { userCartContext } from '../../DataLayer/CartDetails';
import { addToCart } from '../../util/CartFunctions';
import { api } from '../../util/Apis';
import { regularToSnakeCase } from '../../util/StringManipulation';
import Toast from '../../DataLayer/Toast';
import ProductImage from '../../Components/ProductImage/ProductImage';


const ProductPage = () => {
  const navigate = useNavigate()
  const {toast} = useContext(Toast)
  const {userCart,setUserCartDetails}= useContext(userCartContext)
  const {email}= useContext(userContext).userState
  const location = useLocation();
  const myObject = location.state?.product;
  const selectedGame= getDatabaseName(myObject['selectedGame'])
  const productStructure = getProductStructure(selectedGame)
  const [productInfo, setProductInfo] = useState({
    isLiked:false,
    isInCart: false
  })
  const [product, setProduct] = useState({
    ...myObject
  })
  useEffect(() => {
    if(userCart.includes(myObject['product_id']))
      setProductInfo(val=>({...val,isInCart: true}))
    else setProductInfo(val=>({...val,isInCart: false}))
  }, [userCart])
  useEffect(() => {
    window.scrollTo(0, 0);
    getProductDetails(myObject['product_id'],setProduct)
  }, [])
  
  
  return (
    <div className='product_page_layout'>
      <div className="product_section">
        <section className='product_image_section'>
           <div className="product_image_container">
             {/* <div className={"img icon_"+product['game']?.toLowerCase()} ></div> */}
             <ProductImage classList={'img '} game={product['game']?.toLowerCase()} data={product} />
           </div>
        </section>
        <section className="product_details">
            <section>
                <h2>{product['name']}</h2>
                <span >Game Name</span><br />
                <span className="price">${product['price']}</span>
            </section>
            <section>
              <p className="description">
                {product['description']?product['description']:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate velit voluptate nemo inventore, debitis recusandae doloribus optio quibusdam sed atque ullam, quis minima mollitia repellendus magnam iusto! Vel, repellendus quas."}
                
              </p>
            </section>
            <section className='button_section '>
                  <button  className='shiny_glass_effect'
                        onClick={e=>navigate('/buynow/',{state:{product}})}
                   >Buy Now</button>
                  <section>
                          <span ><FaHeart  /> {productInfo.isLiked?"Liked":"Like"}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                          <span onClick={e=>{
                            if(productInfo.isInCart)return
                            addToCart(userCart,setUserCartDetails,product['product_id'],email,toast)}
                          } >
                          <SiShopify /> {productInfo.isInCart?"Added in Cart":"Add to cart"}</span>
                  </section>
            </section>
            <section>
              <span> <em>Category : </em> Video Game</span><br />
              {/* <span><em>Share this product : </em> X X X X</span> */}
            </section>
            <section className='specification' >
              <h3>Specifications</h3>
              <section className='table'>
                {productStructure.slice(2).map((data,index)=>{
                  return(<>
                    <span key={'productpageunikey'+index+"1"}>{data}</span>
                    <span key={'productpageunikey'+index+"12"}>{product[regularToSnakeCase(data) ]?product[regularToSnakeCase(data) ]:" "}</span>
                  </>)
                })}
              </section>
            </section>
        </section>
      </div>
      <div className="related_product"></div>
    </div>
  )
}

export default ProductPage

const getProductDetails=async(productId,setProduct)=>{
  let res = await api("POST",'productdetails',{
    productIds:[productId]
  })
  setProduct(res.data.data[0])
}