import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../Styles/Button/ButtonPrimary.css'

const ButtonPrimary = ({title , href}) => {
  return (
    <button className='btn_primary_1 ' >
      <a href={href}>
      <div className='glass_effect'></div>
              {title}</a>
    </button>
  )
}

export default ButtonPrimary

const ButtonPrimaryLink = ({title , Link}) => {
  const navigate= useNavigate()
  return (
    <button className='btn_primary_1 ' onClick={e=>navigate(Link)}>
      <div className='glass_effect'></div>
              {title}
    </button>
  )
}

export {ButtonPrimary , ButtonPrimaryLink}