import React, { useContext, useEffect } from 'react'
import '../Styles/Payment/PaymentStatus.css'
import { RingLoader as ReactSpinner } from 'react-spinners'
import { PaymentContext } from './Provider/Provider'
const PaymentStatusPage = () => {
    const { visibility } = useContext(PaymentContext).paymentStatusObject
    useEffect(() => { }, [visibility])
    // if (visibility)
    //     return (
    //         <section className="payment_status_page">
    //             <div><ReactSpinner size={200} /></div>
    //             <h1><b>Order Processing</b></h1>
    //             <h3>Please dont refresh the page or click the "Back" or "Close" button of your browser</h3>
    //         </section>
    //     )
    return <></>
}

export default PaymentStatusPage