import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../Styles/GamePage/GamePage.css";
import "../../Styles/GamePage/Pagination.css";
import { HiShoppingCart } from "react-icons/hi";
import { BsFillBagCheckFill } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getGame } from "../../util/GameFunctions";
import { userCartContext } from "../../DataLayer/CartDetails";
import { addToCart } from "../../util/CartFunctions";
import { userContext } from "../../DataLayer/UserContext";
import Toast from "../../DataLayer/Toast";
import ProductImage from "../../Components/ProductImage/ProductImage";

const GamePage = () => {
    let productPerPage=12;
    const [productArray, setProductArray] = useState([])
  const [selectionObject, setSelectionObject] = useState({
    sort: {
      orderBy: true,
      sortBy: undefined,
    },
    filter: {
      seletcedFilter: undefined,
    },
    game: {
      selectedGame: "Counter Strike 2",
    },
  });
  const [expansionObject, setExpansionObject] = useState({
    sort: {
      expand: false,
    },
    filter: {
      expand: false,
    },
    game: {
      expand: false,
    },
  });
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  function onClickHandler(name, subname, value) {
    let temp = { ...selectionObject };
    temp[name][subname] = value;
    setSelectionObject((val) => temp);
  }
  function onClickHandlerExpansion(name, subname, value){
    let temp = { ...expansionObject };
    temp[name][subname] = value;
    setExpansionObject((val) => temp);
  }
  const { game } = useParams();
  useEffect(() => {
    if (game !== undefined) {
      onClickHandler("game", "selectedGame", game);
    }
  }, []);
  useEffect(() => {
    setCurrentPage(val=>1)
    loadGame(1)
    window.scrollTo(0, 0);
  }, [selectionObject]);
  useEffect(() => {
     loadGame(currentPage)
  }, [currentPage])
  
  const loadGame = async (currentPage) => {
    let game = selectionObject.game.selectedGame,
      filter = selectionObject.filter.seletcedFilter,
      sort = selectionObject.sort.sortBy,
      order = selectionObject.sort.orderBy;

    let res = await  getGame(game, filter, sort, order, currentPage).catch(err => {});
    if(res===undefined){
      setProductArray([[[]]])
      return;
    }
    let totalProduct= res.totalRows;
    setTotalPage(Math.ceil(totalProduct/productPerPage))
    setProductArray(res.data);
  };

  return (
    <div className="gamepage_container">
      <div className="game_page_wrapper">
        <FilterSection
          selectionObject={selectionObject}
          expansionObject={expansionObject}
          onClickHandler={onClickHandler}
          onClickHandlerExpansion={onClickHandlerExpansion}
        />
        <ProductSection productArray={productArray} game={selectionObject.game.selectedGame} />
        <div></div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPage={totalPage}
        />
      </div>
    </div>
  );
};

export default GamePage;

const FilterSection = ({ selectionObject, expansionObject, onClickHandlerExpansion, onClickHandler }) => {
  return (
    <section className="filter_section">
      <section className="game_selection">
        <div className="heading pointer" onClick={(e) => onClickHandlerExpansion("game", "expand", !expansionObject.game.expand)}>
          <span className="title">Game</span>
          {expansionObject.game.expand ? (
            <span
              className="icon"
              onClick={(e) => onClickHandlerExpansion("game", "expand", false)}
            >
              {" "}
              -{" "}
            </span>
          ) : (
            <span
              className="icon"
              onClick={(e) => onClickHandlerExpansion("game", "expand", true)}
            >
              {" "}
              +{" "}
            </span>
          )}
        </div>
        <section
          className={`game_list list ${
            expansionObject.game.expand ? "show" : "hide"
          }`}
        >
          {["Counter Strike 2", "Valorant", "GTA V"].map((data, index) => {
            return (
              <span
                key={"filterGameChoice" + index}
                onClick={(e) => onClickHandler("game", "selectedGame", data)}
                className={
                  selectionObject.game.selectedGame === data ? "selected" : ""
                }
              >
                {data}
              </span>
            );
          })}
        </section>
      </section>
      {/* <section className="filter">
        <div className="heading">
          <span className="title"> Filter </span>
          {expansionObject.filter.expand ? (
            <span
              className="icon"
              onClick={(e) => onClickHandlerExpansion("filter", "expand", false)}
            >
              {" "}
              -{" "}
            </span>
          ) : (
            <span
              className="icon"
              onClick={(e) => onClickHandlerExpansion("filter", "expand", true)}
            >
              {" "}
              +{" "}
            </span>
          )}
        </div>
        <section
          className={`filter_list list ${
            expansionObject.filter.expand ? "show" : "hide"
          }`}
        >
          {["Filter 1", "Filter 2", "Filter 3", "Filter 4", "Filter 5"].map(
            (data, index) => {
              return (
                <span
                  key={"filterFilterChoice" + index}
                  onClick={(e) =>
                    onClickHandler("filter", "seletcedFilter", data)
                  }
                  className={
                    selectionObject.filter.seletcedFilter === data
                      ? "selected"
                      : ""
                  }
                >
                  {data}
                </span>
              );
            }
          )}
        </section>
      </section> */}
      <section className="sort">
        <div className="heading pointer" onClick={(e) => onClickHandlerExpansion("sort", "expand", !expansionObject.sort.expand)}>
          <span className="title">Sort</span>
          {expansionObject.sort.expand ? (
            <span
              className="icon"
              onClick={(e) => onClickHandlerExpansion("sort", "expand", false)}
            >
              {" "}
              -{" "}
            </span>
          ) : (
            <span
              className="icon"
              onClick={(e) => onClickHandlerExpansion("sort", "expand", true)}
            >
              {" "}
              +{" "}
            </span>
          )}
        </div>
        <div className="option">
          <span
            onClick={(e) => onClickHandler("sort", "orderBy", true)}
            className={selectionObject.sort.orderBy ? "selected" : ""}
          >
            Ascending
          </span>
          <span
            onClick={(e) => onClickHandler("sort", "orderBy", false)}
            className={selectionObject.sort.orderBy ? "" : "selected"}
          >
            Descending
          </span>
        </div>
        <section
          className={`sort_list list ${
            expansionObject.sort.expand ? "show" : "hide"
          }`}
        >
          <span
            onClick={(e) => onClickHandler("sort", "sortBy", "price")}
            className={
              selectionObject.sort.sortBy === "price" ? "selected" : ""
            }
          >
            Price
          </span>
          {['Date created'].map((data, index) => {
            return (
              <span
                key={"filterSortChoice" + index}
                onClick={(e) => onClickHandler("sort", "sortBy", data)}
                className={
                  selectionObject.sort.sortBy === data ? "selected" : ""
                }
              >
                {data}
              </span>
            );
          })}
        </section>
      </section>
    </section>
  );
};

const ProductSection = ({productArray,game}) => {
  const navigate= useNavigate()
  const {toast} = useContext(Toast)
  const {userCart,setUserCartDetails}= useContext(userCartContext)
  const {email}= useContext(userContext).userState
  return (
    <section className="product_section">
      {productArray.map((data, index) => {
        return (
          <section className="game_card" key={"gameCard" + index}>
            <div className="img_wrapper" onClick={e=>navigateToProduct(navigate,data,game)} >
              {/* <div className={"img icon_"+data['game']?.toLowerCase()}></div> */}
              <ProductImage classList={"img"} game={data['game']?.toLowerCase()} data={data}/>
            </div>
            <section className="icons">
              <span className="icon"
                onClick={e=>addToCart(userCart,setUserCartDetails,data['product_id'],email, toast)}
               title="Add to cart">
                <HiShoppingCart />
              </span>
              <span className="icon" title="Buy Now" 
              onClick={e=>navigate('/buynow/',{state:{product:data}})} >
                <BsFillBagCheckFill />
              </span>
            </section>
            <section className="details" onClick={e=>navigateToProduct(navigate,data,game)}>
              <div className="title" >{structuredGameName(data['game'],data['name'],data)}</div>
              <p onClick={e=>e.stopPropagation()} >
              </p>
              <div className="price">${data['price']}</div>
            </section>
          </section>
        );
      })}
    </section>
  );
};

const Pagination = ({ currentPage, totalPage, setCurrentPage }) => {
  let n = 6;
  let arr = createPaginationArray(currentPage, n, totalPage);
  return (
    <div className="pagination">
      <span
        className={currentPage === 1 ? "disabled" : ""}
        onClick={(e) => {
          if (currentPage === 1) return;
          setCurrentPage(val=>currentPage-1);
        }}
      >
        <FaChevronLeft /> PREV
      </span>
      {arr.map((data, index) => {
        let isDot = false;
        if (data === "...") isDot = true;
        return (
          <span
            className={data === currentPage ? "current_page" : ""}
            key={"PaginationGame" + index}
            onClick={(e) => {
              if (isDot || data === currentPage) return;
              setCurrentPage(data);
            }}
          >
            {data}
          </span>
        );
      })}
      <span
        className={currentPage === totalPage ? "disabled" : ""}
        onClick={(e) => {
          if (currentPage === totalPage) return;
          setCurrentPage(currentPage+1);
        }}
      >
        {" "}
        &nbsp;NEXT <FaChevronRight />
      </span>
      <div className="bottom_bar"></div>
    </div>
  );
};

const createPaginationArray = (currentPage, n, totalPage) => {
  let arr = [];
  if (totalPage <= n) {
    for (let i = 0; i < totalPage; i++) arr.push(i + 1);
    return arr;
  }
  if (currentPage <= n - 2) {
    for (let i = 0; i < n - 2; i++) arr.push(i + 1);
    arr.push("...");
    arr.push(totalPage);
    return arr;
  }
  if (currentPage > totalPage - n) {
    for (let i = totalPage - n + 1; i <= totalPage; i++) arr.push(i);
    return arr;
  }
  for (let i = currentPage - (n - 2); i < currentPage; i++) {
    arr.push(i + 1);
  }
  arr.push("...");
  arr.push(totalPage);
  return arr;
};

const navigateToProduct=(navigate, object, game)=>{
  object['selectedGame']=game;
  navigate('/product', {state : {product:object}})
}

const structuredGameName=(game, name, details)=>{
  switch(game){
    case "pubg":
      return name;
    case "CS2":
      return modifyProductNameForCS2(name,details);
    case "valorant":
      return name;
    default:
      return name;
  }
}

const modifyProductNameForCS2=(name,details)=>{
  const {medals, coins, hours_played}=details
  let medalsArray= medals?.split(',')?.map((data)=>data.trim())
  let medalName=medalsArray?.[0];
  medalsArray?.slice(1,medalsArray?.length)?.map((data)=>{
    medalName+=','+data.substring(2)
  })
  if(medalName) name = `${name} | ${hours_played} Hours Played | ${medalsArray.length} Medals | ${medalName} Medals `
  if(coins){
    let str= coins
    let coinarr= coins.split(',')
    let coinstr=""
    if(coinarr.length>2){
      str = str.replace("10 Year Birthday Coin"," ")
      str = str.replace("Global Offensive Badge"," ")
      str.split(',').forEach((data,index)=>{
        if(data?.trim()){
          let prefix=''
          if(coinstr.length>1)prefix=', '
          coinstr+= prefix+ data.trim()

        }
      })

      name = `${name} | ${coinstr}`
    }
    else name = `${name} | ${coins}`
  }
  return name
}