import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { api } from '../../util/Apis';
import '../../Styles/Verification/Verification.css'
import { userContext } from '../../DataLayer/UserContext';

const VerificationPage = () => {
  const [verificationText, setVerificationText] = useState('Your account is being verified. Please wait.')
  const [isverified, setIsverified] = useState(false)
  const [textLength, setTextLength] = useState(0)
  const {refreshUser}= useContext(userContext)
  const navigate = useNavigate()
  const {email,code} = useParams();
    useEffect(() => {
      async function xyz() {
        try {
          let res = await api("POST",'verifycode',{
            email,
            code
        })
        setTimeout(() => {
          setVerificationText('Your Account Verified Successfully.')
          setTextLength(0)
          setIsverified(true)
          setTimeout(() => {
            navigate('/')
            window.location.reload();
          }, 4500);
        }, 4000);
        } catch (error) {
          setTimeout(() => {
            setVerificationText('Verification Failed. Please try again.')
            setTextLength(0)
          })
        }
      }
      xyz();
    }, [])

    useEffect(() => {
      const interval = setInterval(() => {
        setTextLength((val) => val + 1)
        if(textLength===verificationText.length){
          clearInterval(interval)
        }
      },50)
      return () => clearInterval(interval);
    }, [verificationText])
    
    
  return (
    <div className='verification_page'>
        {!isverified ? <div className="loader"></div>:
        <div className="tick"></div>}
        <div>{verificationText.substring(0,textLength)}</div>

    </div>
  )
}

export default VerificationPage