import React, { useContext, useEffect, useState } from 'react'
import '../../Styles/CartPage/CartPage.css'
import { userContext } from '../../DataLayer/UserContext'
import { api } from '../../util/Apis'
import { userCartContext } from '../../DataLayer/CartDetails'
// import { Elements } from '@stripe/react-stripe-js'
// import { loadStripe } from "@stripe/stripe-js";
// import StripePaymentButton from '../../Components/Payment/Stripe/Stripe'
import PayPalButton from '../../Components/Payment/Paypal/Paypal'
import ErrorBoundary from '../../Components/ErrorBoundary/ErrorBoundary'
import { loginContext } from '../../DataLayer/IsLogin'
import Toast from '../../DataLayer/Toast'
import { RiArrowGoBackFill } from "react-icons/ri";

// const stripePromise = loadStripe("pk_test_51NPrIsSCzhFb83IgMtucv7bcunQ6EKRck1qUfsSvPwfr1p1SAN3BEuZpp6hgSgjZ2bvOzrr7HV92qQUh7WYUuUuD00NAwZJB3W");

const CartPage = () => {
    const user = useContext(userContext).userState
    const {userCart,setUserCartDetails}= useContext(userCartContext)
    const [cartitemsArray, setCartitemsArray] = useState([])
    const [isReadyToCheckout, setIsReadyToCheckout] = useState(false)
    const {setLoginVisibility} = useContext(loginContext)
    const {toast} = useContext(Toast)


    useEffect(() => {
        const func = async()=>{
            // Check if loggedin
            if(user.email===undefined || user.email===''){
                setCartitemsArray([])
                return;
            } 
            // if login , fetch cart details
            if(userCart.length<0)return
                let cartItems= await getCartWithDetails(userCart);
                setCartitemsArray(cartItems)
            // if not login , show no items in cart
            // if cart is emty show empty cart 
        }
        func();
    }, [user,userCart])
    const checkLogin = () => {
        if(!user.email){
            setLoginVisibility(true)
            toast("Please Login to Continue","WARNING")
            return false;
        }
        return true;
    }
    const enableCheckOut = () => {
        if(checkLogin()){
            setIsReadyToCheckout(val=>!val)
        }
    }
    
  return (
    <>
    <div className="card">
            <div className="row">
                <div className="col-md-8 cart">
                    <div className="title">
                        <div className="row">
                            <div className="col"><h4><b>Shopping Cart</b></h4></div>
                            <div className="col align-self-center text-right text-muted">{cartitemsArray.length} items</div>
                        </div>
                    </div>  
                    {
                     cartitemsArray.map((data,index)=>{
                      return(
                          <div className="row border-top border-bottom" key={"cartItems"+index} >
                            <div className="row main align-items-center">
                                <div className={`col-2 cart_product_img ${data['is_sold']?'sold':''}`}><section className={'img icon_'+data['game']?.toLowerCase()}>
                                    <span className='sold_text' >{data['is_sold']?'SOLD':''}</span>
                                </section></div>
                                <div className="col">
                                    <div className="row text-muted">{data['game']}</div>
                                    <div className="row">{data['name']}</div>
                                </div>
                                <div className="col-4">${data['price']}</div>
                                <div className="col-1">
                                    <span className="close" onClick={e=>deleteFromCart(user['email'],data['product_id'],userCart,setUserCartDetails)} >&#10005;</span>
                                </div>
                            </div>
                      </div>
                      )
                     })
                    }
                    <div className="back-to-shop pointer" onClick={e=>window.history.back()} > <RiArrowGoBackFill fill='#6a6a6a' /> <span className="text-muted">Back to shop</span></div>
                </div>
                <div className="col-md-4 summary">
                    <div><h5><b>Summary</b></h5></div>
                    <hr/>
                    <div className="row">
                        <div className="col" style={{paddingLeft:0}}>ITEMS {cartitemsArray.length}</div>
                        <div className="col text-right">${getSumOfPrice(cartitemsArray)}</div>
                    </div>
                    <form>
                        <p>SHIPPING</p>
                        <select><option className="text-muted">Standard-Delivery</option></select>
                        <p>COUPON CODE</p>
                        <input id="code" placeholder="Enter your code"/>
                    </form>
                    <div className="row" style={{borderTop: "1px solid rgba(0,0,0,.1)", padding: "2vh 0"}}>
                        <div className="col">TOTAL PRICE</div>
                        <div className="col text-right">${getSumOfPrice(cartitemsArray)}</div>
                    </div>
                    <button className="btn" onClick={e=>enableCheckOut()} >{isReadyToCheckout?"CANCEL":"CHECKOUT" }</button>
                            <PayPalButton isReadyToCheckout={isReadyToCheckout} cart={sanitisedCart(cartitemsArray)} />
                    {/* <ErrorBoundary> */}
                    {/* </ErrorBoundary> */}
                    {/* <Elements stripe={stripePromise}>
                    <StripePaymentButton/>
                </Elements> */}
                </div>
            </div>
            
        </div>
    </>
  )
}

export default CartPage

const getCartWithDetails=async(userCart)=>{
    const serializedArray = userCart.join(',');
    let res = await api('GET','getcartwithdetails?productIds=' + encodeURIComponent(serializedArray))
    .catch(err=>{
    })
    if(!res?.data?.data)return []
    return res.data.data;
}

const deleteFromCart=async(email,productId,userCart,setUserCartDetails )=>{
    let res = await api('POST','deletecart',{productId , email})
    .catch(err=>{
    })
    if(res.status===204){
        let arr= [];


        if(userCart.length===1) {
            setUserCartDetails([]) 
                return
        }
        userCart.forEach(data=>{
            if(data!==productId)arr.push( data);
        })
        setUserCartDetails([...arr])
    }
}

const getSumOfPrice=(array=[])=>{
    let sum=0;
    array.forEach(data=>{
        if(data['is_sold']==1)return
        sum+= parseFloat(data['price']) 
    })
    return (Math.round(sum*100))/100
}

const sanitisedCart = (userCart) => {
    let arr = [];
    userCart.forEach(data=>{
        if(data['is_sold']!=1)
        arr.push(data['product_id'])
    })
    return arr
}