import React, { useContext, useEffect, useState } from 'react'
import '../../Styles/CartPage/CartPage.css'
import { userContext } from '../../DataLayer/UserContext'
import { api } from '../../util/Apis'
import { userCartContext } from '../../DataLayer/CartDetails'
import { useLocation } from 'react-router-dom'
import PayPalButton from '../../Components/Payment/Paypal/Paypal'
import Toast from '../../DataLayer/Toast'
import { loginContext } from '../../DataLayer/IsLogin'
import { RiArrowGoBackFill } from "react-icons/ri";


const SingleCart = () => {
    const location = useLocation();
    const myObject = location.state?.product;
    const user = useContext(userContext).userState
    const {toast} = useContext(Toast)
    const {setLoginVisibility} = useContext(loginContext)
    const {userCart,setUserCartDetails}= useContext(userCartContext)
    const [cartitemsArray, setCartitemsArray] = useState([myObject])
    const [isReadyToCheckout, setIsReadyToCheckout] = useState(false)
    const checkLogin = () => {
        if(!user.email){
            setLoginVisibility(true)
            toast("Please Login to Continue","WARNING")
            return false;
        }
        return true;
    }
    const enableCheckOut = () => {
        if(checkLogin()){
            setIsReadyToCheckout(val=>!val)
        }
    }
  return (
    <>
    <div className="card">
            <div className="row">
                <div className="col-md-8 cart">
                    <div className="title">
                        <div className="row">
                            <div className="col"><h4><b>Shopping Cart</b></h4></div>
                            <div className="col align-self-center text-right text-muted">{cartitemsArray.length} items</div>
                        </div>
                    </div>  
                    {
                     cartitemsArray.map((data,index)=>{
                      return(
                          <div className="row border-top border-bottom" key={"cartItems"+index} >
                            <div className="row main align-items-center">
                            <div className="col-2 cart_product_img"><section className={'img icon_'+data['game']?.toLowerCase()}></section></div>
                                <div className="col">
                                    <div className="row text-muted">{data['game']}</div>
                                    <div className="row">{data['name']}</div>
                                </div>
                                <div className="col-4">${data['price']}</div>
                                <div className="col-1">
                                    {/* <span className="close" onClick={e=>deleteFromCart(user['email'],data['product_id'],userCart,setUserCartDetails)} >&#10005;</span> */}
                                </div>
                            </div>
                      </div>
                      )
                     })
                    }
                    <div className="back-to-shop pointer" onClick={e=>window.history.back()} > <RiArrowGoBackFill fill='#6a6a6a' /> <span className="text-muted">Back to shop</span></div>
                </div>
                <div className="col-md-4 summary">
                    <div><h5><b>Summary</b></h5></div>
                    <hr/>
                    <div className="row">
                        <div className="col" style={{paddingLeft:0}}>ITEMS {cartitemsArray.length}</div>
                        <div className="col text-right">${getSumOfPrice(cartitemsArray)}</div>
                    </div>
                    <form>
                        <p>SHIPPING</p>
                        <select><option className="text-muted">Standard-Delivery</option></select>
                        <p>COUPON CODE</p>
                        <input id="code" placeholder="Enter your code"/>
                    </form>
                    <div className="row" style={{borderTop: "1px solid rgba(0,0,0,.1)", padding: "2vh 0"}}>
                        <div className="col">TOTAL PRICE</div>
                        <div className="col text-right">${getSumOfPrice(cartitemsArray)}</div>
                    </div>
                    <button className="btn" onClick={e=>enableCheckOut()} >{isReadyToCheckout?"CANCEL":"CHECKOUT" }</button>
                            <PayPalButton isReadyToCheckout={isReadyToCheckout} cart={[myObject.product_id]} />
                    
                </div>
            </div>
            
        </div>
    </>
  )
}

export default SingleCart


const deleteFromCart=async(email,productId,userCart,setUserCartDetails )=>{
    let res = await api('POST','deletecart',{productId , email})
    if(res.status===204){
        setUserCartDetails([...userCart.map(data=>{
            if(data!==productId)return data;
        })])
    }
}

const getSumOfPrice=(array=[])=>{
    let sum=0;
    array.forEach(data=>{
        sum+= parseFloat(data['price']) 
    })
    return (Math.round(sum*100))/100
}