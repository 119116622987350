import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Provider from "./Components/Provider/Provider";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Homepage from "./Pages/Homepage/Homepage";
import GamePage from "./Pages/GamePage/GamePage";
import LoginRegister from "./Components/LoginRegister/LoginRegister";
import AutoLogin from "./Components/LoginRegister/AutoLogin";
import CartPage from "./Pages/CartPage/CartPage";
import ProductPage from "./Pages/ProductPage/ProductPage";
import SingleCart from "./Pages/SingleCart/SingleCart";
import VerificationPage from "./Pages/AccountVerification/VerificationPage";
import ToastContainer from "./Components/Toast/Toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clientId } from "./util/GoogleAuth";
import OrdersPage from "./Pages/OrdersPage/OrdersPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "./Pages/ReturnPolicy/ReturnPolicy";
import PaymentStatusPage from "./Components/PaymentStatusPage";

function App() {
  return (
    <main>
      <GoogleOAuthProvider clientId={clientId}>
        <Provider>
          <ToastContainer>
            <BrowserRouter>
              <Navbar />
              <AutoLogin />
              <LoginRegister />
              <PaymentStatusPage/>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/game" element={<GamePage />} />
                <Route path="/games" element={<GamePage />} />
                <Route path="/game/:game" element={<GamePage />} />
                <Route path="/games/:game" element={<GamePage />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/buynow" element={<SingleCart />} />
                <Route path="/product" element={<ProductPage />} />
                <Route path="/verify/:email/:code" element={<VerificationPage />} />
                <Route path="/orders/*" element={<OrdersPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<PrivacyPolicy />} />
                <Route path="/cancellation" element={<ReturnPolicy />} />
                <Route path="*" element={<Homepage />} />
              </Routes>
              <Footer />
            </BrowserRouter>
          </ToastContainer>
        </Provider>
      </GoogleOAuthProvider>
    </main>
  );
}

export default App;
